import setupAxios from '../setupAxios'
import WebpackerReact from 'webpacker-react'

import AppWrapper from 'components/shared/AppWrapper'

import tenant from '../public/tenant'
import moment from 'moment'
import 'moment/locale/da'
import 'moment/locale/nb'

setupAxios()

window.addEventListener('DOMContentLoaded', setupAxios)
import * as Routes from '../../routes.js.erb'
window.Routes = Routes
window.tenant = tenant
window.isHjemis = () => {
  return window.tenant === 'hjem-is'
}
window.isHjemis() ? moment.locale('da') : ''
window.tenant === 'den-norske-isbilen' ? moment.locale('nb') : ''

WebpackerReact.setup({
  AppWrapper,
})
