const TENANTS = {
  'dk': 'hjem-is',
  'no': 'den-norske-isbilen'
}

let tenant

if (window.location.pathname.split('/')[1] === 'no') {
  tenant = 'den-norske-isbilen'
} else if (window.location.hostname.includes('sms.hjem-is')) {
  tenant = 'hjem-is'
}

export default tenant
